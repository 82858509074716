import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import Software from "../../../software/Software";
import {sessionService} from "../../../session";
import { useCookies } from "react-cookie";
import BotaoDourado from "../../../components/botao-dourado/BotaoDourado";

interface ItemMenuProps {
  software: Software;
  logout: ()=>void;
}

const ItemMenu = (props: ItemMenuProps) => {
  const software = props.software;
  const cookieName: string = 'refresh';
  
  const [cookies, setCookie, removeCookie] = useCookies([cookieName])

  const abrirLink = async () => {
    if (!(await sessionService.atualizaSessao())) {
      props.logout();
      return;
    }

    setCookie(cookieName, sessionService.getSession()?.getRefreshToken(), {domain:"galvanilog.com.br", expires: new Date((new Date()).getTime() + 5000)});

    //Gerar o cookie para testar em desenvolvimento
    if(software.getUrl().indexOf('://localhost') > -1){
      setCookie(cookieName, sessionService.getSession()?.getRefreshToken(), {domain:"localhost", expires: new Date((new Date()).getTime() + 5000)});
    }

    window.open(software.getUrl(), "_blank");
  };

  const link = software.getShowInMenu() ? (
    <BotaoDourado label="Acessar" onClick={abrirLink} />
  ) : (
    ""
  );

  return (
    <li>
      <Panel header={software.getName()}>
        <p>{software.getDescription()}</p>
        {link}
      </Panel>
    </li>
  );
};

export default ItemMenu;

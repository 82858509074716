import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useState } from "react";
import AuthApi from "../../api/AuthApi";
import BotaoDourado from "../../components/botao-dourado/BotaoDourado";
import { sessionService } from "../../session";
import Session from "../../session/Session";
import logo from "./logo-galvani.png";
import "./login.css";

interface LoginProps {
  onLogin: () => void;
}

function Login(props: LoginProps) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const doLogin = async () => {
    const session = await AuthApi.doLogin(login, password);
    if (session === undefined) {
      alert("Usuário ou senha inválidos!");
      return;
    }

    sessionService.save(session as Session);
    props.onLogin();
  };

  return (
    <div className="grid mt-5">
      <div className="col-12 flex justify-content-center"><img src={logo} alt="Galvani Logística" className="logo-login" /></div> 
       <div className="col-12 flex justify-content-center mt-5">
      <Card title="Login" className="md:w-25rem card-shadow">
        <div className="m-2">
          <InputText
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            placeholder="Login"
          />
        </div>
        <div className="m-2">
          <Password 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Senha"
            feedback={false}
            onKeyDown={(e)=>{
               if(e.key === "Enter" && login !== "" && password !== "")
               {
                  doLogin();
               }
            }}
          />
        </div>
        <div className="m-2 mt-3 flex justify-content-center">
          <BotaoDourado label="Entrar" onClick={() => doLogin()}></BotaoDourado>
        </div>
      </Card>
      </div>
    </div>
  );
}

export default Login;

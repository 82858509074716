import { Avatar } from "primereact/avatar";
import * as PrimeMenu from "primereact/menu";
import { Menubar } from "primereact/menubar";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { sessionService } from "../../session";
import SessaoExpiradaError from "../../session/SessaoExpirada";
import { softwareService } from "../../software";
import Software from "../../software/Software";
import ItemMenu from "./item/ItemMenu";
import logo from "./logo-galvani.png";
import "./menu.css";

interface MenuProps {
  onLogout: () => void;
}

const Menu = (props: MenuProps) => {
  const [softwares, setSoftwares] = useState(new Array<Software>());
  const [loaded, setLoaded] = useState(false);
  const [userName,setUserName] = useState("-");
  const menu = useRef<PrimeMenu.Menu>(null);
  const toast = useRef<Toast>(null);
  
  const logout = () => {
    sessionService.logout();
    props.onLogout();
  };

  const popupItems = [
              {
                  label: 'Sair',
                  command: logout
              }
  ];

  const getSoftwares = async () => {
    const session = sessionService.getSession();
    if (session === undefined) {
      logout();
      return;
    }
    
    try{
      setSoftwares(await softwareService.getSoftwares(sessionService.getIdUsuario()));
    } catch(error){
      console.log(error);
      if(error instanceof SessaoExpiradaError){
        logout();
      }
    }
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getSoftwares();
      setUserName(sessionService.getUsername());
    }
  },[loaded, getSoftwares]);

  const items = softwares.map((s) => (
                  <ItemMenu key={s.getId()} software={s} logout={logout}/>
                )); 

  const userAvatar = () => {
    let userIniciais = ""
    if(userName !== undefined){
      const userArr = userName.split(' ');
      userIniciais = userArr[0].charAt(0) + (userArr.length > 1 ? userArr[userArr.length-1].charAt(0) : "");
    }
    return <>
      <PrimeMenu.Menu model={popupItems} popup ref={menu} id="menu" popupAlignment="left" />
      <Toast ref={toast}></Toast>
      <Avatar label={userIniciais.toLocaleUpperCase()} size='large' shape='circle'  style={{ backgroundColor: '#c7ae7b', color: '#ffffff' }}  onClick={(event) => menu?.current?.toggle(event)} aria-controls="popup" aria-haspopup ></Avatar>
    </>
  }
   
  const menuLogo = () => <img src={logo} alt="Galvani Logística" className="menu-logo"/>

  return (
    <>
    <nav id="appMenu" className='grid'>
        <div className="col"><Menubar start={menuLogo} end={userAvatar()}/></div>
    </nav>
      <div className="card flex justify-content-center m-0 mt-5">
        <ul>
          {items}
        </ul>
      </div>
    </>
  );
};


export default Menu;

import AuthJWTPayload, { Module, UserAccess } from "./AuthJWTPayload";

export default class UserAccessCheck {
  private userAccess!: UserAccess;

  constructor(softwareId: number, payload: AuthJWTPayload) {
    const ua = payload.access.userAccess.find(
      (userAccess) => userAccess.software.id === softwareId
    );

    if (ua === undefined) {
      throw new Error(`Usuário não tem acesso ao software ${softwareId}`);
    }

    this.userAccess = ua;
  }

  userHasAccess(module: string): boolean {
    const modules = this.filterModules(module);
    return modules.length > 0;
  }

  private filterModules(module: string): Array<Module> {
    return this.userAccess.roles.flatMap((role) =>
      role.modules.filter((m) => m.module === module)
    );
  }

  userHasPermission(module: string, permission: string): boolean {
    const modules = this.filterModules(module);

    const permissions = modules.flatMap((module) => module.permissions);

    const idx = permissions.findIndex((p) => p === permission);

    return idx !== -1;
  }
}
